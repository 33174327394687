export const cardData = [
  {
    name: "Alice",
    about: "this is a thing that means nothing",
  },
  {
    name: "slice",
    about: "this is a thing that means nothing",
  },
  {
    name: "mary",
    about: "this is a thing that means nothing",
  },
];
export const button = [
  {
    btn: "Read More",
  },
];
