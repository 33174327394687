import React from "react";
import cardImg from "../assets/png/Creative-CV.jpg";
import { useNavigate } from "react-router-dom";
import { button, cardData } from "./Helper";

const TrialsCard = () => {
  const navigate = useNavigate();

  const onclickHandler = (value) => {
    localStorage.setItem("items", JSON.stringify(value));
    navigate(`/details/${value.name}`);
  };

  return (
    <>
      <div className="flex mt-16">
        {Array.isArray(cardData) &&
          cardData.map((obj, index) => {
            return (
              <div className="w-1/3 px-3">
                <div
                  key={index}
                  className="lg:ps-6 ps-2 cursor-pointer pe-2 pt-5 md:pt-14 pb-7 border border-solid border-[#0000001A] hover:border-transparent transition-all duration-300 feature_card_shadow rounded-[5px] h-full flex flex-col justify-between bg-[#fff] relative"
                >
                  <div className={`flex justify-center items-center w-full `}>
                    <img
                      src={cardImg}
                      width="178"
                      height="168"
                      alt="feature-card"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-end mt-2">
                    <h2 className="ff_poppins font-bold text-xl sm:text-2xl md:text-[28px] text-black mb-0 md:mb-2.5 leading-[42px]">
                      {obj.name}
                    </h2>
                    <p className="ff_popping font-normal text-base text-black leading-6 mb-0 md:mb-[14px]">
                      {obj.about}
                    </p>

                    {button.map((cell, cellIndex) => (
                      <div
                        key={cellIndex}
                        onClick={() => onclickHandler(obj)}
                        className="bg-blue-200 border border-gray-400 flex items-center justify-center m-1"
                      >
                        {cell.btn}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default TrialsCard;
