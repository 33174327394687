import React from "react";
import cardImg from "../assets/png/Creative-CV.jpg";

const DetailsPage = () => {
  const items = JSON.parse(localStorage.getItem("items"));

  return (
    <>
      {" "}
      <div className="flex mt-16 group w-2/5">
        <div className="w-full px-3 mirror group-hover:scale-x-100 transition-all duration-300">
          <div className="lg:ps-6 ps-2 cursor-pointer pe-2 pt-5 md:pt-14 pb-7 border border-solid border-[#0000001A] hover:border-transparent transition-all duration-300 feature_card_shadow rounded-[5px] h-full flex flex-col justify-between bg-[#fff] relative">
            <div className={`flex justify-center items-center w-full `}>
              <img
                className="mirror"
                src={cardImg}
                width="178"
                height="168"
                alt="feature-card"
              />
            </div>
            <div className="h-full flex flex-col justify-end mt-2">
              <h2 className="ff_poppins font-bold text-xl sm:text-2xl md:text-[28px] text-black mb-0 md:mb-2.5 leading-[42px]">
                {items.name}
              </h2>
              <p className="ff_popping font-normal text-base text-black leading-6 mb-0 md:mb-[14px]">
                {items.about} <br />
                <span className="mirror">{items.about}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsPage;
