import "./App.css";
import CustomSlider from "./components/CustomSlider";
import DetailsPage from "./components/DetailsPage";
import { cardData } from "./components/Helper";
import TrialsCard from "./components/TrialsCard";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<TrialsCard />} />
        <Route path="/details/:any" element={<DetailsPage />} />
        <Route path="/slider" element={<CustomSlider />} />
      </Routes>
    </>
  );
}

export default App;
