import React, { useState } from "react";

const CustomSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 1,
      text: "Slide 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 2,
      text: "Slide 2: Nulla facilisi. Sed eget odio euismod, consequat odio sed, cursus metus.",
    },
    {
      id: 3,
      text: "Slide 3: Vivamus auctor leo eget sem interdum, sed ultrices dolor laoreet.",
    },
    {
      id: 4,
      text: "Slide 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 5,
      text: "Slide 2: Nulla facilisi. Sed eget odio euismod, consequat odio sed, cursus metus.",
    },
    {
      id: 6,
      text: "Slide 3: Vivamus auctor leo eget sem interdum, sed ultrices dolor laoreet.",
    },
  ];

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="max-w-screen-lg mx-auto relative mt-16 bg-hero-pattern bg-cover bg-no-repeat ">
      <div className="flex justify-center items-center ">
        <div className="overflow-hidden w-full">
          <div
            className="flex transition-transform duration-500"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide) => (
              <div key={slide.id} className="w-full flex-shrink-0 ">
                <div className="w-full h-64 flex items-center justify-center  rounded-lg">
                  <p>{slide.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        onClick={handlePrevSlide}
        className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-blue-500 text-white px-4 py-2 rounded shadow-md"
      >
        Prev
      </button>
      <button
        onClick={handleNextSlide}
        className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-blue-500 text-white px-4 py-2 rounded shadow-md"
      >
        Next
      </button>
    </div>
  );
};

export default CustomSlider;
